'use client';

import { useEffect, useRef } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { useSession } from 'next-auth/react'


const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string;

const AmplitudeTracker: React.FC = () => {
  const { data: session, status } = useSession()



  const initialized = useRef(false);

  useEffect(() => {
    if (session?.user?.email && status === 'authenticated') {
      amplitude.setUserId(session.user.email)
    }
    else {
      amplitude.setUserId(undefined)
    }
  }, [session]);

  useEffect(() => {
    if (!initialized.current) {
      if (AMPLITUDE_API_KEY) {
        amplitude.init(AMPLITUDE_API_KEY, {
          defaultTracking: {
            // pageViews: false, // Disable automatic page view tracking
            formInteractions: false, // Disable automatic form submission and start tracking
            sessions: false, // Disable automatic session tracking
          },
        });

      }
      initialized.current = true;
    }
  }, []);


  return <></>;
};

export default AmplitudeTracker;
