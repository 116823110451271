import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/app/globals.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/components/Amplitude/amplitude-tracker.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/components/Navbar/loginModal.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/contexts/AuthContextProvider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/contexts/CommonContext.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/codebuild/output/src485114418/src/ai-ecom/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Sans\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"notoSans\"}")